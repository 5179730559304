<template>
  <div style="background-color: white;min-height: 650px;padding: 20px;box-sizing: border-box;">
    <div style="display: flex;justify-content: space-between;">
      <div class="tag-container">
        <div v-for="(tag, index) in allTags" :key="index" @click="handleSelectTag(tag)">
          <p :class="selectedTag == tag ? 'tag-item-selected tag-item-normal' : 'tag-item-normal'">{{ tag }}
          </p>
        </div>
      </div>
      <el-input v-model="searchProject" style="width: 320px;" @input="handleSearch" placeholder="Search"></el-input>
    </div>

    <div style="margin-top: 20px;">
      <div v-for="(product, index) in filterList" :key="index" class="product-item"
        @click="showProductDetail(product._id.$id)">
        <img :src="product.project_logo" class="product_logo">
        <div class="item-second">
          <p class="product_name">{{ product.project_ename }}</p>
          <div class="tag-list" v-if="product.tags && product.tags.length > 0">
            <p class="product_tag" v-for="(tag, index) in product.tags.split(',')" :key="index">{{ tag }}
            </p>
          </div>
        </div>
        <div class="product_ddl">
          <!--          <img src="../../assets/image/icon_time1.png" style="width: 16px;height: 16px;margin-right: 5px;">-->
          <p>Sign up before <br /> {{ $dayjs(product.deadline_date).format("MMM DD, YYYY") }}</p>
        </div>
        <div class="product_intro">
          {{ product.short_intro }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {
  getServicePrograms
} from '../../api/eae'
import '../../assets/common/common.css'
import '../../assets/common/font.css'
export default ({
  name: 'index',
  data() {
    return {
      selectedTag: '',
      dataList: [],
      allTags: ['All', 'Olympiad', 'PBL', 'Business', 'Individual', 'Team'],
      searchProject: 'All',
      filterList: []
    }
  },
  mounted() {
    this.fetchData(true)
  },
  methods: {

    handleSearch(value) {
      if (value) {
        this.searchProject = value
        this.filterList = []
        for (let i = 0; i < this.dataList.length; i++) {
          let product = this.dataList[i]
          if (product.project_ename.indexOf(value) >=0) {
            this.filterList.push(product)
          }
        }
      }else {
        this.filterList = this.dataList
      }
    },

    handleSelectTag(tag) {
      this.selectedTag = tag
      this.fetchData(true)
    },

    formatTags(product) {
      let tempTag = ''
      if (product.tags && product.tags != '') {
        let tempsTags = product.tags.split(',')
        for (let tag of tempsTags) {
          tempTag += tag + ' '
        }
      }
      return tempTag
    },

    showProductDetail(id) {
      this.$router.push({
        path: '/home/programsDetail',
        query: {
          project_id: id,
          from: 'student'
        }
      })
    },

    fetchData(showLoading) {
      let loading = null
      if (showLoading) {
        loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      }
      let key_tag = ''
      if (this.selectedTag != 'All') {
        key_tag = this.selectedTag
      }
      getServicePrograms(key_tag, 'student').then((res) => {
        let result = res.data
        this.dataList = result
        this.filterList = result
        if (loading) {
          loading.close();
        }
      })
    }
  }

})
</script>
<style scoped lang="scss">
/deep/ .el-input__inner {
  border-radius: 5px;
  background-color: #f5f5f5;
}

.tag-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;

  .tag-item-normal {
    padding: 0px 6px;
    min-width: 85px;
    height: 38px;
    text-align: center;
    line-height: 38px;
    background-color: #f5f5f5;
    color: #999;
    font-size: 14px;
    font-family: centurygothic_bold;
    cursor: pointer;
    border: 1px solid white;
  }

  .tag-item-selected {
    background-color: #FFF8F7;
    color: var(--color-primary);
    border: 1px solid #FF6450;
  }
}

.product-item {
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #E6D7D7;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 20px;

  .product_logo {
    width: 80px;
    height: 80px;
    object-fit: cover;
  }

  .item-second {
    padding: 0px 15px;
    box-sizing: border-box;
    flex: 2;

    .product_name {
      color: #333;
      font-size: 14px;
      font-family: centurygothic_bold;
    }

    .tag-list {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      margin-top: 8px;
    }

    .product_tag {
      padding: 2px 4px;
      display: inline-block;
      background-color: #FFF2CC;
      box-sizing: border-box;
      color: #333;
      font-size: 12px;
      line-height: 22px;
      font-family: centurygothic;
    }
  }


  .product_ddl {
    flex: 1;
    display: flex;
    align-items: center;
    width: 200px;
    color: #FC6556;
    font-size: 14px;
    font-family: centurygothic_bold;
  }

  .product_intro {
    flex: 3;
    height: 100%;
    color: #666;
    font-size: 12px;
    line-height: 20px;
    font-family: centurygothic;

    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;
  }
}
</style>